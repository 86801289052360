import React from "react";

interface ScoreSliderProps {
  scoreUpdate: (newScore: number) => void;
  sliderScore: number;
}

export function ScoreSlider(props: ScoreSliderProps) {
  return <input onChange={(changeEvent) => props.scoreUpdate(parseFloat(changeEvent.target.value) / 100.0)} type="range" name="wib-range" className="wib-range"></input>;

}
