import React, { FC, useEffect, useState, useRef } from "react";
import { WIBAvailability, useDateTimesInRange } from "./Lib";
import { DateTime, Duration } from "luxon";
import { CSSTransition } from 'react-transition-group';
import chroma from "chroma-js";

interface DayElementProps {
  start: DateTime;
  end: DateTime;
  sectionLength: Duration;
  intervalAvailabilityMap: Map<string, WIBAvailability>;
  updateIntervalAvailabilityMap?: ((key: string, value: WIBAvailability) => void);
  sliderValue?: number;
}


export const DayElement: FC<DayElementProps> = (props) => {
  const colorScale = useRef(chroma.scale(["#fa3737", "#facf37", "#30e82c"]));
  const [color, setColor] = useState<chroma.Color>(colorScale.current(0))

  const [displayTimes, setDisplayTimes] = useState(false);
  const { intervalAvailabilityMap, updateIntervalAvailabilityMap, sectionLength } = props;
  const times = useDateTimesInRange(props.start, props.end, sectionLength);

  useEffect(() => {
    let valToAverage = 0;
    for(let val of times) {
      const relevantAvailability: WIBAvailability | undefined = intervalAvailabilityMap.get(val.toISO());
      if(relevantAvailability){
        valToAverage += relevantAvailability.score
      }
    }

    setColor(colorScale.current(valToAverage / times.length));
  }, [colorScale, intervalAvailabilityMap, times])
 
  const onTimeClick = (event: React.MouseEvent<HTMLElement>, isoVal: string) => {
    event.stopPropagation();
    // if there's no slidervalue (the DayElement is in view mode) this function can chill
    if(props.sliderValue === undefined){
      return;
    }
    const availability = intervalAvailabilityMap.get(isoVal);
    if (availability && updateIntervalAvailabilityMap) {
      availability.score = props.sliderValue;
      updateIntervalAvailabilityMap(isoVal, availability);
    }
  };

  return <div role="group" aria-label={props.start.toLocaleString()} className="day-element" style = {{backgroundColor: color.css()}} onClick={() => setDisplayTimes(!displayTimes)}>
    <h1 className="serif day-week">
      {props.start.toLocaleString({ weekday: 'long' }).toLocaleLowerCase()},

    </h1>
    <h1 className="serif month">
      {props.start.toLocaleString({ month: 'long' }).toLocaleLowerCase()}
    </h1>
    <h1 className="serif day-number">
      {props.start.toLocaleString({ day: '2-digit' })}
    </h1>
    <CSSTransition
      in={displayTimes}
      classNames="times-container"
      timeout={200}
    >
      <div className="times-container">
        {times.map((val, ind) => {
          // confirm we have the actual map
          if ("get" in props.intervalAvailabilityMap) {
            // grab availability value, could be undefined
            const relevantAvailability: WIBAvailability | undefined = props.intervalAvailabilityMap.get(val.toISO());
            return relevantAvailability && <div role="menuitem" aria-label={val.toLocaleString({month:"2-digit", day:"2-digit" ,hour:"numeric","minute":"numeric"})} onClick={(event) => onTimeClick(event, val.toISO())} key={val.toISO()} className="time-object" style={{ "backgroundColor": colorScale.current(relevantAvailability.score).css() }}>
              {val.toLocaleString(DateTime.TIME_SIMPLE)}
            </div>;

          } else { // if the actual map isn't ready for us yet just have it be red
            return <div role="menuitem" aria-label={val.toLocaleString({month:"2-digit", day:"2-digit" ,hour:"numeric","minute":"numeric"})} key={val.toISO()} onClick={(event) => onTimeClick(event, val.toISO())} className="time-object" style={{ "backgroundColor": colorScale.current(0).css() }}>
              {val.toLocaleString(DateTime.TIME_SIMPLE)}
            </div>;
          }
        })}
      </div>
    </CSSTransition>
  </div>;

};


