import React, { useState } from "react";
import { WIBEvent, compressIntervalMap, respondToEvent, useDateTimesInRange, useAvailableDuration, usePopulatedIntervalAvailabilityMap } from "./Lib";
import { Duration } from "luxon";
import { EditForm } from "./EditForm";
import { ScoreSlider } from "./ScoreSlider";
import { DayElement } from "./DayElement";

const TRUE = "true";

interface EditEventProps {
  link: string;
  relevantEvent: WIBEvent | undefined;
  setOnViewScreen: React.Dispatch<React.SetStateAction<boolean>>
}

export function EditEvent(props: EditEventProps) {
  const { link, relevantEvent, setOnViewScreen } = props;

  const [sliderScore, setSliderScore] = useState<number>(.5);
  const days = useDateTimesInRange(relevantEvent?.startDate, relevantEvent?.endDate, Duration.fromObject({ days: 1 }));
  const availableDuration = useAvailableDuration(relevantEvent?.startDate, relevantEvent?.endDate);

  const [intervalAvailabilityMap,
    , 
    updateIntervalAvailabilityMap] = usePopulatedIntervalAvailabilityMap(relevantEvent?.startDate, relevantEvent?.endDate, relevantEvent?.sectionLength);

  const onSubmit = (submitParams: { name: string; }) => {
    compressIntervalMap(intervalAvailabilityMap).then((compressedMap) => {
      respondToEvent(link, submitParams.name, compressedMap).then(() => {
        window.localStorage.setItem(link, TRUE);
        setOnViewScreen(true);
      });
    });
  };

  const onScoreUpdate = (newScore: number) => {
    setSliderScore(newScore);
  };

  return <div className="columns">
    <div className="column is-one-quarter">
        <EditForm
          onSubmit={onSubmit} />
        <ScoreSlider
          sliderScore={sliderScore}
          scoreUpdate={onScoreUpdate} />
    </div>
    <div className="column">
      <div className="days-container">
        {relevantEvent && days.map((val, ind) => {
          return <DayElement
            key={ind}
            start={val}
            end={val.plus(availableDuration)}
            sectionLength={relevantEvent.sectionLength}
            intervalAvailabilityMap={intervalAvailabilityMap}
            updateIntervalAvailabilityMap={updateIntervalAvailabilityMap}
            sliderValue={sliderScore} />;
        })}
      </div>
    </div>
  </div>

}
