import React, { ChangeEventHandler, useEffect, useRef, useState } from "react";

interface InputProps {
  style?: React.CSSProperties 
  name: string
  onChange: ChangeEventHandler<HTMLInputElement>
  placeholder?: string
  placeholderType?: "top" | "variable"
  type?: "text" | "date"
}
 
const Input = (props: InputProps) => {
  const [styleInjection, setStyleInjection] = useState<React.CSSProperties>();
  // take placeholder out b/c we don't want it on the input anymore :)
  const {style, onChange, name} = props;
  
  useEffect(() => {
    if(props.placeholderType === "top"){
      // if it's not variable then we can just set the style injection once and we're good to go
      setStyleInjection({"transform":"translate(0,-4.5vh)", "fontSize":"1em", "zIndex":1});
    }
  }, [props.placeholderType])

  const variableOrFixedPlaceholder = () => {
    const inputType = props.type === "date" ? "date" : "text";

    if(props.placeholderType === undefined || props.placeholderType === "variable"){
      return <div className='input-div'>
        <input className="input" type = {inputType} {...{style, onChange, name}} onFocus={(event) => setStyleInjection({"transform":"translate(0,-4.5vh)", "fontSize":"1em", "zIndex":1})} onBlur={(event) => event.target.value.length === 0 ? setStyleInjection({}) : undefined }></input> 
        <div className="input-placeholder" style={styleInjection}>{props.placeholder}</div>
      </div>
    }else {
      return <div className='input-div'>
        <input className="input" type = {inputType} {...{style, onChange, name}}></input> 
        <div className="input-placeholder" style={styleInjection}>{props.placeholder}</div>
      </div>
    }
  };

  return variableOrFixedPlaceholder();
}
 
export default Input;