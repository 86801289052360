import chroma from "chroma-js";
import React, { useEffect, useRef, useState } from "react"
import { Redirect } from "react-router";
import ColorChangingButton from "./ColorChangingButton";

function Landing(): JSX.Element {
  const [redirectToCreate, setRedirectToCreate] = useState(false);
  const colorScale = useRef(chroma.scale(["#fa3737", "#facf37", "#30e82c"]));

  // TODO: implement this

  return <div className="section">
    <div id="bg-container"></div>
    <div className="landing">
        <object type="image/svg+xml" data="2.svg" style={{"zIndex": -1}}></object>
        <ColorChangingButton 
          colorScale = {colorScale.current} 
          text="create!" 
          onClick={() => setRedirectToCreate(true)} 
          style={{ alignSelf: "center", marginTop:"10vh"}} />
      {redirectToCreate && <Redirect push to="/create" />}
    </div>
  </div>
}

export default Landing