import React from 'react';
import ReactDOM from 'react-dom';
import './styles.scss';
import Landing from './Landing';
import Create from './Create';
import ViewAndSubmitScreen from "./ViewAndSubmitScreen";
// import Edit from './Edit';
// import View from './View';
import reportWebVitals from './reportWebVitals';

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Switch>
        <Route path="/create">
          <Create/>
        </Route>
        <Route path="/:link" render={(props) => <ViewAndSubmitScreen {...props}/>}/>
        <Route path="/">
          <Landing />
        </Route>
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
