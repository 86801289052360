import React, { useState } from "react";
import { Button } from "./Button";
import Input from "./Input";

interface EditFormProps {
  onSubmit: (submitParams: { name: string; }) => void;
  onReset?: () => void;
}
export function EditForm(props: EditFormProps) {
  const [values, setValues] = useState<{ name?: string; }>({});

  const submitPressed = (event: any) => {
    if (typeof values.name === 'string') {
      props.onSubmit({ name: values.name });
    }
  };
  return <div style={{"display":"flex", "flexDirection":"column", "margin":"10px"}}>
    <Input name = "name" placeholder = "your name" onChange={(val) => setValues({ name: val.target.value })} />
    <Button text={"submit!"} onClick={submitPressed} />
  </div>;
}

export default EditForm


