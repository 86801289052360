import React, { useEffect, useState } from "react"
import { RouteComponentProps, Redirect } from "react-router-dom"
import { viewEvent, WIBEvent } from "./Lib";

// import { WIBAvailability } from "./Stuff";
import { EditEvent } from "./EditEvent";
import {ViewEvent} from "./ViewEvent";

function ViewAndSubmitScreen(props: RouteComponentProps<{link: string}>) {
  const [gotoHome, setGotoHome] = useState(false);
  const [relevantEvent, setEvent] = useState<WIBEvent | undefined>(undefined);
  const [onViewScreen, setOnViewScreen] = useState<boolean>(false);

  useEffect(() => {
    const link = props.match.params.link;
    viewEvent(link).then((val) => {
      if(val !== false){
        // the object is returned, it worked!
        setEvent(val);
      } 
    }).catch((err) => {
      // if it errored then send them back to landing
      setGotoHome(true);
    })
  }, [props.match.params.link])

  // if the user has already made a response, take them to the view page
  useEffect(() => {
    if(window.localStorage.getItem(props.match.params.link) === "true"){
      setOnViewScreen(true);
    }
  }, [props.match.params.link])

 return <div>{gotoHome && <Redirect to="/"/>}
 <div id="bg-container"></div>
 <nav className="navbar" role="navigation" aria-label="main navigation">
  <div className="navbar-brand" style={{"flexDirection":"column"}}>
    {/* <a className="navbar-item" href="https://bulma.io">
      <img src="https://bulma.io/images/bulma-logo.png" width="112" height="28"/>
    </a> */}

    {/* */}
      <a href="/" className="navbar-item" style={{"justifyContent":"center"}}>
        <object type="image/svg+xml" data="2.svg" style={{"width":"53%", "zIndex":-1}}>
        </object>
      </a>
      <div style={{"justifyContent":"center", "display":"flex"}}>
        <button className="toggleguy sansserif" onClick = {() => setOnViewScreen(false)}>edit</button>
        <button className="toggleguy sansserif" >|</button>
        <button className="toggleguy sansserif" onClick = {() => setOnViewScreen(true)}>view</button>
      </div>
    </div>

    {/* <svg width="894" height="177" viewBox="0 0 894 177" fill="none" xmlns="http://www.w3.org/2000/svg" /> */}

    {/* <a role="button" className="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
    </a> */}

  {/* <div className="navbar-end">
    <div className="navbar-item">
      <div className="buttons">
        <a className="button is-primary">
          <strong>Sign up</strong>
        </a>
        <a className="button is-light">
          Log in
        </a>
      </div>
    </div>
  </div> */}
</nav>
 
 {!onViewScreen && <EditEvent setOnViewScreen = {setOnViewScreen} link={props.match.params.link} relevantEvent={relevantEvent}/>}
 {(onViewScreen && relevantEvent )&& <ViewEvent relevantEvent={relevantEvent}/>}
 </div>
}

export default ViewAndSubmitScreen;