import { Interval, DateTime, Duration } from "luxon";
import axios, { AxiosResponse } from 'axios';
import { BACKEND_URL } from "./Constants";
import { useEffect, useState } from "react";
import { WIBResponseTransit, WIBEventTransit, WIBAvailabilityTransit } from "./WIBAvailabilityTransit";

export class WIBEvent {
  name: string;
  link: string;
  startDate: DateTime;
  endDate: DateTime;
  sectionLength: Duration;
  responses: WIBResponseTransit[]

  constructor(name: string, link: string, startDate: DateTime, endDate: DateTime, sectionLength: Duration, responses?: WIBResponseTransit[]){
    this.name = name;
    this.link = link;
    this.startDate = startDate;
    this.endDate = endDate;
    this.sectionLength = sectionLength;
    
    if(responses !== undefined){
      this.responses = responses;
    } else{
      this.responses = [];
    }
  }

  toTransit(): WIBEventTransit{
    const ret =  {
      name: this.name,
      link: this.link,
      startDate: this.startDate.toISO(),
      endDate: this.endDate.toISO(),
      sectionLength: this.sectionLength.toISO()
    }

    return ret;
  }

  static fromTransit(transitObj: WIBEventTransit): WIBEvent {
    return new this(transitObj.name, transitObj.link, DateTime.fromISO(transitObj.startDate), 
      DateTime.fromISO(transitObj.endDate), Duration.fromISO(transitObj.sectionLength),
      transitObj.responses);
  }
}

export class WIBAvailability {
  interval: Interval;
  score: number;

  constructor(interval: Interval, score: number) {
    this.interval = interval;
    this.score = score;
  }

  static fromTransit(transitObj: WIBAvailabilityTransit): WIBAvailability {
    return new this(Interval.fromISO(transitObj.interval), transitObj.score);
  }

  toTransit(): WIBAvailabilityTransit {
    let ret = {
      interval: this.interval.toISO(),
      score: this.score
    };

    return ret;
  }
  
  /**
   * combine two availabilities with the same score and adjacent intervals. modifies this availability in-place.
   * @param  {WIBAvailability} next
   * 
   */
  combine(next: WIBAvailability) {
    if(this.futureAdjacent(next) && this.score === next.score){
      this.interval = this.interval.union(next.interval)
    } else {
      new Error("can't combine without matching scores and adjacent intervals.");
    }
  }

  futureAdjacent(next: WIBAvailability): boolean{
    return this.interval.abutsStart(next.interval);
  }
}

export async function respondToEvent(link: string, username: string, availabilities: WIBAvailability[]) {
  let availabilitiesTransit = availabilities.map((val) => {
    return val.toTransit();
  });

  let apiResponse: AxiosResponse = await axios.post(BACKEND_URL + "/respond", { link, username, response: availabilitiesTransit });

  if (apiResponse.status === 200) {
    return true;
  }
  else {
    return false;
  }
}

export async function createEvent(eventToCreate: WIBEvent | WIBEventTransit): Promise<boolean> {
  // type guard for figuring out if it's a WIBEvent or WIBEventTransit
  function isEvent(event: WIBEvent | WIBEventTransit): event is WIBEvent {
    return (event as WIBEvent).toTransit !== undefined;
  }

  // do the check, if it's a WIBEvent then change it to transit
  if (isEvent(eventToCreate)) {
    eventToCreate = eventToCreate.toTransit();
  }

  // now that we know it's WIBTransit we can pass it straight to axios
  let response: AxiosResponse = await axios.post(BACKEND_URL + "/create", eventToCreate);

  if (response.status === 200) {
    return true;
  }
  else {
    return false;
  }
}

export async function deleteEvent(linkToDelete: string): Promise<boolean> {
  const body = {
    link: linkToDelete,
    secret: "supa secret value nobody will ever guess huehuehue"
  };

  let response: AxiosResponse = await axios.post(BACKEND_URL + "/delete", body);

  if (response.status === 200) {
    return true;
  }
  else {
    return false;
  }
}

export async function viewEvent(linkToView: string): Promise<WIBEvent | false> {
  const body = {
    link: linkToView,
  };

  let response: AxiosResponse = await axios.post(BACKEND_URL + "/view", body);

  if (response.status === 200) {
    return WIBEvent.fromTransit(response.data as WIBEventTransit);
  }
  else {
    return false;
  }
}


/**
 * Collapses a map of ISO format startdates and WIBAvailability objects by filtering out any with 0 score and combining availabilities when they occur with the same score adjacent to one another.
 * @param intervalMap {Map<string, WIBAvailability>} the map, as used in ViewEdit for O(1) updates to availabilities
 */
export const compressIntervalMap = async (intervalMap: Map<string, WIBAvailability>): Promise<WIBAvailability[]> => {
  let lastAvailabilitySet: WIBAvailability | null = null;
  let availabilitiesFinal: WIBAvailability[] = [];

  const intervalMapIterator = intervalMap.values();
  
  let availabilityIterItem = intervalMapIterator.next();
  // this is *almost* redundant but we need it in case of 0 length
  while(!availabilityIterItem.done) {

    let availability = availabilityIterItem.value;

    // the default is 0 so we don't need to put anything
    if(availability.score !== 0){
      if(lastAvailabilitySet !== null && 
        lastAvailabilitySet.score === availability.score && 
        lastAvailabilitySet.futureAdjacent(availability)){
          lastAvailabilitySet.combine(availability);

      }else {
        // if this doesn't work this is why
        lastAvailabilitySet = availability;
        availabilitiesFinal.push(availability);
      }

    }
    availabilityIterItem = intervalMapIterator.next();
    if (availabilityIterItem.done) {
      return availabilitiesFinal
    }
  }
  return availabilitiesFinal;
}

/**
 * given the start, end , and senctionLength, will return an array of DateTimes representing the begininng of each of those sections
 * @param start the DateTime representing the very start of the range
 * @param end the DateTime for the very end of the range
 * @param sectionLength the length of each individual section of time
 */
export function useDateTimesInRange(start: DateTime | undefined, end: DateTime | undefined, sectionLength: Duration | undefined): DateTime[]{
  const [times, setTimes] = useState<DateTime[]>([]);
  
  // if the difference in ms btwn start and end is <= 0 ??? makes sense????
  useEffect(() => {
    if(start !== undefined && end !== undefined && sectionLength !== undefined){
      let timeArray : DateTime[] = [];
      // copy so modifying this in place doesn't trigger rerender
      // NOTE: i do not know that this works to prevent that lol
      let startCopy: DateTime = DateTime.fromISO(start.toISO());
      let go = true;

      // hoping this means it won't do again on rerender once the array is populated
      while (times.length === 0 && go){
        timeArray.push(startCopy);
        startCopy = startCopy.plus(sectionLength);

        if(startCopy > end){
          go = false;
          setTimes(timeArray);
      }
    }}
  },[end, sectionLength, start])

  return times;
}

/**
 * returns a Duration that represents the time between the start and end date, with day month and year being equal. (the available hours in one day)
 * @param startDate the start of the range, DateTime
 * @param endDate the end of the range, DateTime
 */
export function useAvailableDuration(startDate: DateTime | undefined, endDate: DateTime | undefined): Duration{
  const [dailyDuration, setDailyDuration] = useState<Duration>(Duration.fromObject({millisecond:0}));
  useEffect(() => {
    if(startDate !== undefined && endDate !== undefined){
      let copyEnd = endDate;
      copyEnd = copyEnd.set({day: startDate.day, month: startDate.month, year: startDate.year})
      setDailyDuration(copyEnd.diff(startDate,["minutes", "hours"]));
    }
  }, [startDate, endDate]);

  return dailyDuration;
}

export function useMap<K,V>(){
  const [stateMap, setMap] = useState<Map<K,V>>(new Map<K,V>());

  const updateMap = (key: K, value: V) => {
    // we have to clone the map so that (<-- this back was written by past john, i no longer remember why)
    setMap(new Map(stateMap.set(key,value)));
  }

  return [stateMap, setMap, updateMap] as const;
}

export function usePopulatedIntervalAvailabilityMap(start: DateTime | undefined, end: DateTime | undefined, sectionLength: Duration | undefined):
[intervalAvailabilityMap: Map<string, WIBAvailability>, 
  setIntervalAvailabilityMap: React.Dispatch<React.SetStateAction<Map<string, WIBAvailability>>>,
  updateIntervalAvailabilityMap: (key: string, value: WIBAvailability) => void] {

  const times = useDateTimesInRange(start, end, sectionLength);
  const [intervalAvailabilityMap,
    setIntervalAvailabilityMap,
    updateIntervalAvailabilityMap] = useMap<string, WIBAvailability>();

  useEffect(() => {
    let temp = new Map<string, WIBAvailability>();
    let needToUpdate = false;
    // this could probably be something to memoize later
    for(let val of times){
      // we only want to update if there's no value in IAM already
      if(sectionLength && intervalAvailabilityMap.get(val.toISO()) === undefined){
        temp.set(val.toISO(), new WIBAvailability(Interval.fromDateTimes(val, val.plus(sectionLength)), 0));
        needToUpdate = true;
      }
    };
    if(needToUpdate){
      setIntervalAvailabilityMap(temp);
    }

  }, [intervalAvailabilityMap, sectionLength, setIntervalAvailabilityMap, times])

  return [intervalAvailabilityMap, setIntervalAvailabilityMap, updateIntervalAvailabilityMap];
}

