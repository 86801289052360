import chroma from "chroma-js";
import { useEffect, useRef, useState } from "react";
import { Button, ButtonProps } from "./Button";

interface ColorChangingButtonProps extends ButtonProps {
  colorScale: chroma.Scale
  reverseScale?: boolean
}
 
const ColorChangingButton = (props: ColorChangingButtonProps) => {
  const [mousePosition, setMousePosition] = useState([0,0])
  const buttonDomRef = useRef<HTMLButtonElement>(null);
  const [currentColor, setCurrentColor] = useState<chroma.Color>(props.colorScale(0));

  useEffect(() => {
    const mouseMoveHandler = (event: MouseEvent) => {
      setMousePosition([event.clientX, event.clientY]);
    };
    document.addEventListener("mousemove", mouseMoveHandler);

    return () => {
      document.removeEventListener("mousemove", mouseMoveHandler);
    }
  }, [])

  useEffect(() => {
    const getColorFromMousePosition = (position: Array<number>): chroma.Color => {
      let boundingRect = buttonDomRef.current?.getBoundingClientRect();
      if(boundingRect !== undefined){
        const center_x = boundingRect.left + boundingRect.width / 2;
        const center_y = boundingRect.top + boundingRect.height / 2;

        // distance formula to center of the button 
        const distance = Math.sqrt(((mousePosition[1] - center_y) ** 2) + ((mousePosition[0] - center_x) ** 2));
    
        // get the max distance with some cheeky pythagorean
        const maxDistance = Math.sqrt(center_x ** 2 + center_y ** 2);
    
        // take the average
        return props.reverseScale ? props.colorScale(distance / maxDistance) : props.colorScale(1.0 - distance / maxDistance);
      }
      return props.colorScale(0);
    }

    setCurrentColor(getColorFromMousePosition(mousePosition))

  }, [mousePosition])


  return <Button ref={buttonDomRef} {...props} style={{...props.style, "backgroundColor": currentColor.css()}}/> 
}
 
export default ColorChangingButton;