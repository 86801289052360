// note to self in case this doesn't make it out of high me's brain: the way im going to implement this is:

// 1. pass the event object down to this component, 
// 2. have this component initialize an array availabliity map with all the datetimes that we'll need
// 2b. have a state object for the names of the people and boolean enabled in view
// 3. (memo) have a method to query the response availabilities, queryResponse(isovalue: string) => only go through the responses of the 
// poeple that are enabled, check if the iso is within an availability we find, if yes than copy to array that we'll average to get 
// the final value 
// 4. set the availability of the iso for the intervalavailabilitymap to that average value

import { Duration, Interval } from "luxon";
import React, { useEffect, useState } from "react"
import { DayElement } from "./DayElement";
import { useAvailableDuration, useDateTimesInRange, usePopulatedIntervalAvailabilityMap, WIBEvent } from "./Lib"

interface ViewEventProps {
  relevantEvent: WIBEvent 
}

export function ViewEvent(props: ViewEventProps) {
  // the names of the respondants that have responded
  const [activeNames, setActiveNames] = useState<string[]>([])
  const { relevantEvent } = props;
  const days = useDateTimesInRange(relevantEvent.startDate, relevantEvent.endDate, Duration.fromObject({ days: 1 }));
  const availableDuration = useAvailableDuration(relevantEvent.startDate, relevantEvent.endDate);
  const [intervalAvailabilityMap,
    ,
    updateIntervalAvailabilityMap] = usePopulatedIntervalAvailabilityMap(relevantEvent.startDate,relevantEvent.endDate, relevantEvent.sectionLength);

  // initially populate the activeNames
  useEffect(() => {
    let temp = [];
    for(const response of relevantEvent.responses) {
      temp.push(response.username)
    }
    setActiveNames(temp)
  }, [relevantEvent])

  useEffect(() => {
    // go through the intervals that are in this event and calculate their scores
    for(let [_, relevantInterval] of intervalAvailabilityMap.entries()) {
      let scoresToAverage: number[] = [];
      for(let responseInd = 0; responseInd < relevantEvent.responses.length; responseInd++) {
        const res = relevantEvent.responses[responseInd];
        if(activeNames.includes(res.username)) {
          for(let availability of res.availabilities) {
            // if the intervals overlap at all
            const tempIntersection = relevantInterval.interval.intersection(Interval.fromISO(availability.interval));
            if(tempIntersection !== null && tempIntersection !== undefined ) {
              const lenDuration = tempIntersection.toDuration("minutes");
              const len = lenDuration.minutes;
              if(len >= 30){
                scoresToAverage.push(availability.score)
              }
            }
          }
        }
      }

      let score = 0;
      
      // if we don't have a response from everyone then the score needs to stay zero
      if(scoresToAverage.length === activeNames.length){
        for(var heck of scoresToAverage) {
          // if someone responded zero, we can leave this in, but also need to handle the no response sitch
          if(heck === 0){
            score = 0;
            scoresToAverage = [];
            break;
          }
          score += heck;
        }
        score = scoresToAverage.length > 0 ? score / scoresToAverage.length : 0;
      }
      if(relevantInterval.score !== score) {
        relevantInterval.score = score;
        updateIntervalAvailabilityMap(_, relevantInterval);
      }
    }
  }, [activeNames, intervalAvailabilityMap, relevantEvent.responses, updateIntervalAvailabilityMap]);

  function toggleActive(name: string) {
    if(activeNames.includes(name)){
     setActiveNames(activeNames.filter((el) => el !== name))
    } else {
      setActiveNames(activeNames.concat(name))
    }
  }

  function styleName(name: string) {
    if(!activeNames.includes(name)){
      return {textDecorationLine:"line-through"}
    }
  }

  return <div className="columns">
    <div className="column is-one-quarter">
      <div className="box form-box" style={{marginLeft:"1.75em"}}>
        { relevantEvent.responses.map((val, ind) => {
          return <div style={styleName(val.username)} key={val.username} onClick = {() => toggleActive(val.username)}>{val.username}</div>
        }) }
      </div>
    </div>
    <div className="column">
      <div className="days-container">
        { relevantEvent && days.map((val, ind) => {
          return <DayElement
            key={ind}
            start={val}
            end={val.plus(availableDuration)}
            sectionLength={relevantEvent.sectionLength}
            intervalAvailabilityMap={intervalAvailabilityMap}
            updateIntervalAvailabilityMap={updateIntervalAvailabilityMap}
            />;
        }) }
      </div>
    </div>
  </div>;

}

