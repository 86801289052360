import React, { useState, useEffect } from "react";
import { DateTime, Duration } from "luxon";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import TimeRangePicker from '@wojtekmaj/react-timerange-picker';
import { createEvent, WIBEvent } from "./Lib";
import { Redirect } from "react-router-dom";
import Input from "./Input";
import ColorChangingButton from "./ColorChangingButton";
import chroma from "chroma-js";

function Create(): JSX.Element {
  const [gotoEdit, setGotoEdit] = useState(false);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [startTime, setStartTime] = useState<string>("");
  const [endTime, setEndTime] = useState<string>("");

  const [timeRangePickerValues, setTimeRangePickerValues] = useState<string[]>(["09:00", "21:00"]);
  const [name, setName] = useState<string>("");
  const [link, setLink] = useState<string>("");
  const [range, setRange] = useState<DateTime[]>([]);

  useEffect(() => {
    const dateFormat = "yyyy-MM-dd/h a";
    const startDateString = `${startDate}/${startTime}`
    const endDateString = `${endDate}/${endTime}`

    const startDateTime = DateTime.fromFormat(startDateString, dateFormat);
    const endDateTime = DateTime.fromFormat(endDateString, dateFormat);
    

    setRange([startDateTime, endDateTime]);
    // setRange(pickerToDateTime);
  }, [startDate, endDate, startTime, endTime]);

  function handleSubmit(){
    const newEvent = new WIBEvent(name, link, range[0], range[1], Duration.fromObject({ minutes:30 }))
    createEvent(newEvent).then((res) => {
      if(res){
        setGotoEdit(true)
      } 
    })
  }

  return <div>
    <div id="bg-container"></div>
    <nav className="navbar" role="navigation" aria-label="main navigation" style={{"marginBottom":"20px"}}>
      <div className="navbar-brand" style={{"flexDirection":"column"}}>
        <a href="/" className="navbar-item" style={{"justifyContent":"center",}}>
          {<object type="image/svg+xml" data="2.svg" style={{"width":"50%", "zIndex":-1}}>
          </object>}
        </a>
      </div>
    </nav>
    <div className="columns">
        <div className="column"></div>
        <div className="column is-half create-column">
          <Input name="eventname"  placeholder="event name" onChange={(val) => setName(val.target.value)}></Input>
          <Input name="link" placeholder="link" onChange = {(val) => setLink(val.target.value)}></Input>
          {/* <DateRangePicker
            onChange = {setDateRangePickerValues}
            value = {dateRangePickerValues}
          />
          <TimeRangePicker
            onChange = {setTimeRangePickerValues}
            value = {timeRangePickerValues}
          /> */}
          <div style={{"display":"flex"}}>
            <div style={{"width":"calc(50% - 5px)", "marginRight":"10px"}}>
              <Input placeholder="start date" placeholderType="top" name="startDate" onChange={(event) => setStartDate(event.target.value)} type="date"></Input>
            </div>
              <div style={{"width":"calc(50% - 5px"}}>
                <Input placeholder="end date" placeholderType="top" name="startDate" onChange={(event) => setEndDate(event.target.value)} type="date"></Input>
              </div>
          </div>
          <div style={{"display":"flex"}}>
            <div style={{"width":"calc(50% - 5px)", "marginRight":"10px"}}>
              <Input placeholder="start time" name="startTime" onChange={(event) => setStartTime(event.target.value)}></Input>
            </div>
              <div style={{"width":"calc(50% - 5px"}}>
                <Input placeholder="end time" name="endTime" onChange={(event) => setEndTime(event.target.value)}></Input>
              </div>
          </div>
          <ColorChangingButton colorScale = {chroma.scale(["#fa3737", "#facf37", "#30e82c"])} text="submit" onClick={handleSubmit} />
    
          {gotoEdit && <Redirect to = {`/${link}`} />}
    
        </div>
        <div className="column"></div>
      </div>
  </div>
}

export default Create;