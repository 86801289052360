import React from "react";

export interface ButtonProps {
  onClick: (event: any) => void;
  style?: React.CSSProperties
  text: string;
}

// forward the ref b/c we use this in higher order components now
export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>((props: ButtonProps, ref) => {
  return <button ref={ref} className="wib-button button is-large serif" style={props.style} onClick={(e) => props.onClick(e)}>{props.text}</button>;
})
